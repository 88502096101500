.modal-content {
    border-radius: 8px !important; 
    box-shadow: 0 4px 16px 2px rgba(0,0,0,0.16), 0 2px 4px 0 rgba(0,0,0,0.16);
}

.kep-login-facebook {
    border-radius: 4px !important; 
    font-family: 'SF Pro Text Semibold' !important;
    font-size: 15px !important;
    letter-spacing: 0.79px !important; 
}   
