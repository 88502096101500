#signupModal {
    max-width: 75% !important;
}

.termsAgreeButton {
    position: absolute; 
    bottom: 0; 
}

@media (max-width: 650px) {
    #signupModal {
        max-width: unset !important;
    }
}

@media (max-width: 500px) {
    #facebookButtonSignUp {
        margin: 0 auto !important;
    }
}