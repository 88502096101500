.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade.modal-backdrop.show { 
  z-index: 1000000;
}

.swal-overlay.swal-overlay--show-modal {
  z-index: 100000000
}

.ReactCrop__image { 
  width: 100%;
}

/* Customize the SweetAlert background */
.swal-overlay {
  background-color: rgba(0, 0, 0, 0.8); /* Darker overlay */
}

/* Customize the SweetAlert modal */
.swal-modal {
  border-radius: 8px;
  font-family: 'SF Pro Text';
  padding: 2px;
  background-image: linear-gradient(#3a3933, #3a3933), linear-gradient(270deg, #ffe500 0%, #fac51c 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

/* Customize the title */
.swal-title {
  color: #ffcc00;
  font-size: 24px;
  font-weight: bold;
}

/* Customize the text */
.swal-text {
  color: #b6bcbf;
  font-size: 18px;
  margin-top: 10px;
}

/* Customize the confirm button */
.swal-button {
  -webkit-box-align: center !important;
    background: linear-gradient(270deg, rgb(255, 229, 0) 0%, rgb(250, 197, 28) 100%) !important;
    height: 32px !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 4px !important;
    font-family: "SF Pro Text Semibold" !important;
    font-size: 15px !important;
    letter-spacing: 0.79px !important;
    text-align: center !important;
    color: rgb(58, 57, 52) !important;
    padding: calc(13.3884px + 0.34435vw) calc(18.3884px + 0.34435vw) !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px 0px, rgba(0, 0, 0, 0.16) 0px 4px 16px 2px !important;
}